/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}
h1 {
    color: red;
}
.react-switch {
    vertical-align: middle;
    margin-left: 4px;
}

.react-switch-sm {
    transform: scale(0.7, 0.7);
}

.TextTiny {
    font-size: x-small;
}
.TPTitle {
    margin: auto;
    width: 20%
}

.TPBox {
    margin: auto;
    width: 100%;
    position: relative;
}
.bg-green {
    background-color: palegreen !important;
}
.top-buffer {
    margin-top: 20px;
}

.top-buffer-small {
    margin-top: 5px;
}
.autoz {
    z-index:auto;
}
.minz {
    z-index: -999;
}
.preserve-breaks {
    white-space:pre-line
}
.minwidth100 {
    min-width:100%;
}

.loadingbackdrop {
    margin: 0px; padding: 0px; position: fixed; right: 0px; top: 0px; width: 100%; height: 100%; background-color: rgb(102, 102, 102); z-index: 99999; opacity: 0.8; 
}
.loadingdisplay {
    position: absolute;
    color: White;
    top: 50%;
    left: 45%;
}

.glowMeBlue {
    -moz-box-shadow: 0 0 10px rgba(25, 40, 212, 0.75);
    -webkit-box-shadow: 0 0 10px rgba(25, 40, 212, 0.75);
    box-shadow: 0 0 10px rgba(25, 40, 212, 0.75);
}

.glowMeRed {
    -moz-box-shadow: 0 0 10px rgba(238, 20, 20, 0.75);
    -webkit-box-shadow: 0 0 10px rgba(238, 20, 20, 0.75);
    box-shadow: 0 0 10px rgba(238, 20, 20, 0.75);
}

.whitespace {
    white-space:pre-line;
}

.evenRow {
    background-color: rgba(0,0,0,0.05)
}

.vcentre {
    display: flex !important;
    align-items: center;
}

.textRight {
    text-align: right;
}

.textSmall {
    font-size: small;
}
.cursorPointer {
    cursor: pointer
}

.gridHeaderCell {
    font-weight:bold;
    white-space:normal;
    word-wrap:break-word;

}
.leaflet-container {height: 50vh;}

.hoverdrag:hover {
    cursor:grab
}

.hoverdrag:active {
    cursor:grabbing
}

form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    pointer-events: none;
}
